/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import * as yup from 'yup';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState, useEffect, useRef } from 'react';

import Spinner from '../../components/Spinner';
import Builder from '../../components/Builder';
import FieldGroup from '../../components/FieldGroup';
import RouterLink from '../../components/RouterLink';
import TriggerDropdown from '../../components/TriggerDropdown';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const validationSchema = yup.object({
  name: yup.string().label('List Name').max(32).required(),
  description: yup.string().label('Description').max(100),
});

const ListBuilder = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { listId } = useParams();
  const [chartDetails, setChartDetails] = useState({});
  const chartRef = useRef(null);
  let chartInstance = useRef(null);
  const [display, setDisplay] = useState(false);

  const { mutateAsync: saveList } = useMutation(
    data =>
      axios.request({
        url: listId ? `/partner-lists/${listId}/edit` : '/partner-lists',
        method: listId ? 'put' : 'post',
        data,
      }),
    {
      onSuccess: async response => {
        queryClient.invalidateQueries('partnerLists');
        queryClient.invalidateQueries('list');

        if (listId) {
          history.push('/list-manager');
        } else {
          history.push(`/list-manager/${response.id}/contacts`);
        }
      },
    },
  );

  const { data: list } = useQuery(
    ['list', listId],
    () => axios.get(`/partner-lists/${listId}`),
    {
      enabled: !!listId,
      onError: () => {
        history.push('/404');
      },
      onSuccess: res => {
        const fallbackTime = () => {
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0');
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        setChartDetails({
          hourly_data: res.hourly_data || [],
          current_db_time: res.current_db_time || fallbackTime(),
        });
        setDisplay(true);
      },
    },
  );

  useEffect(() => {
    if (chartRef.current && display) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const chartData = {
        labels: chartDetails.hourly_data.map(item => {
          const date = new Date(item.date_created);
          return date.getHours() === 0 ? date.toLocaleDateString() : '';
        }),
        datasets: [
          {
            label: 'Hourly Records',
            data: chartDetails.hourly_data.map(item => item.hourly_records),
            borderColor: '#E00001',
            backgroundColor: 'rgba(224, 0, 1, 0.2)',
            borderWidth: 2,
            tension: 0.4,
            pointRadius: 0, // Changed: remove visible points
            pointHoverRadius: 0, // Changed: no hover radius
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        interaction: {
          mode: 'index', // Changed: show tooltip on index
          intersect: false, // Changed: no need to intersect exactly
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'List Size History',
          },
          tooltip: {
            mode: 'index', // Changed: tooltip mode
            intersect: false, // Changed: no intersect needed
            callbacks: {
              label: function (context) {
                const dataIndex = context.dataIndex;
                const dateCreated =
                  chartDetails.hourly_data[dataIndex]?.date_created;
                let cleanedDate = dateCreated.split(' ')[0];
                let cleanedTime = dateCreated
                  .split(' ')[1]
                  .split(':')
                  .slice(0, 2)
                  .join(':');
                return [
                  `Date: ${cleanedDate + ' ' + cleanedTime}`,
                  `Records: ${context.raw}`,
                ];
              },
            },
            displayColors: false,
          },
        },
        scales: {
          x: {
            type: 'category',
            ticks: {
              maxRotation: 45,
              autoSkip: false,
              callback: function (value, index) {
                const date = new Date(this.getLabelForValue(index));
                return date.getHours() === 0 ? date.toLocaleDateString() : '';
              },
            },
          },
          y: {
            title: {
              display: true,
              text: 'Hourly Records',
            },
            // beginAtZero: true,
            // min: 0,
          },
        },
        layout: {
          padding: 10,
        },
        backgroundColor: 'white',
      };

      chartInstance.current = new ChartJS(chartRef.current, {
        type: 'line',
        data: chartData,
        options: chartOptions,
      });
    }
  }, [chartDetails]);

  if (listId && !list) {
    return <Spinner fixed />;
  }

  const initialValues = {
    name: list?.name || '',
    description: list?.description || '',
    trigger_id: list?.trigger_id || '',
  };

  return (
    <>
      {display ? (
        <canvas
          ref={chartRef}
          height="80%"
          style={{
            backgroundColor: '#FFFFFF',
            // transform: 'scale(0.90, 0.80)',
          }}
        ></canvas>
      ) : null}
      <br />
      <Builder
        onSubmit={saveList}
        initialValues={initialValues}
        validationSchema={validationSchema}
        title="Create/Edit List"
        okText={listId ? 'Save' : 'Next'}
        cancelButtonProps={{
          component: RouterLink,
          to: '/list-manager',
        }}
      >
        <FieldGroup title="List Details">
          <Field name="name">
            {({ field, meta }) => (
              <TextField
                {...field}
                label="List Name"
                placeholder="Enter List Name"
                helperText={meta.touched && meta.error}
                error={meta.touched && Boolean(meta.error)}
              />
            )}
          </Field>
          <Field name="description">
            {({ field, meta }) => (
              <TextField
                {...field}
                multiline
                rows={4}
                label="Description"
                placeholder="Enter Description for List"
                helperText={meta.touched && meta.error}
                error={meta.touched && Boolean(meta.error)}
              />
            )}
          </Field>
        </FieldGroup>
        <Field name="trigger_id" component={TriggerDropdown} />
      </Builder>
    </>
  );
};

export default ListBuilder;
