import {
  CtCtIcon,
  KeapIcon,
  ZohoIcon,
  EmmaIcon,
  OHWOIcon,
  DripIcon,
  SendyIcon,
  FwcrmIcon,
  AWeberIcon,
  KlentyIcon,
  HunterIcon,
  MailerLiteIcon,
  KlaviyoIcon,
  GoHighLevelIcon,
  OngageIcon,
  MaropostIcon,
  WebhookIcon,
  HubSpotIcon,
  MadMimiIcon,
  MailjetIcon,
  SendFoxIcon,
  SendgridIcon,
  IContactIcon,
  OmnisendIcon,
  BluetickIcon,
  PepipostIcon,
  SendlaneIcon,
  IntercomIcon,
  MailChimpIcon,
  BenchmarkIcon,
  AutopilotIcon,
  AutokloseIcon,
  SendPulseIcon,
  SendinblueIcon,
  CampaignerIcon,
  SalesforceIcon,
  ConvertKitIcon,
  SmartReachIcon,
  Connect365Icon,
  GetResponseIcon,
  NeverBounceIcon,
  EmailOctopusIcon,
  ElasticEmailIcon,
  SmartEmailingIcon,
  ZapierWebhookIcon,
  ActiveCampaignIcon,
  CampaignMonitorIcon,
  SalesforceMarketingIcon,
  OntraportIcon,
  BirdSendIcon,
  CustomerIoIcon,
  BlastableIcon,
  OutreachIcon,
  PostupIcon,
  MailWizzIcon,
  PardotIcon,
  ApolloIoIcon,
  EmarsysIcon,
  CMCommerceIcon,
  BeehiivIcon,
} from '../assets/icons/datatablesIcons.js';
import CampaignIntegrationIcon from '../assets/images/logo.png';
export const inboxMailersCampaign = {
  type: 'inbox_mailers_campaign',
  title: 'Inbox Mailers',
};
export const integrationMethods = {
  API_KEY: {
    title: 'API Key',
    fields: [
      {
        name: 'api_key',
        label: 'API Key',
      },
    ],
  },
  API_SECRET: {
    title: 'API Secret',
    fields: [
      {
        name: 'api_key',
        label: 'API Secret',
      },
    ],
  },
  ACCESS_TOKEN: {
    title: 'Access Token',
    fields: [
      {
        name: 'custom_properties.accessToken',
        label: 'Access Token',
      },
    ],
  },
  GET: {
    title: 'GET',
    hasWebhookField: true,
    fields: [],
  },
  POST: {
    title: 'POST',
    hasWebhookField: true,
    fields: [],
  },
  ICONTACT_CUSTOM: {
    title: 'iContact API',
    fields: [
      {
        name: 'custom_properties.apiAppId',
        label: 'Application ID',
      },
      {
        name: 'custom_properties.apiUsername',
        label: 'Username / Email Address',
      },
      {
        name: 'custom_properties.apiPassword',
        label: 'API Password',
      },
    ],
  },
  OAUTH2: {
    title: 'OAuth 2.0',
    hasAuthorization: true,
    fields: [],
  },
  SALESFORCE_MARKETING: {
    title: 'Client Credentials',
    fields: [
      {
        name: 'custom_properties.clientId',
        label: 'Client ID',
      },
      {
        name: 'custom_properties.clientSecret',
        label: 'Client Secret',
      },
      {
        name: 'custom_properties.subdomain',
        label: 'Subdomain Name',
        helperText:
          'Your subdomain is represented by a 28-character string starting with the letters "mc"',
      },
    ],
  },
  PARDOT: {
    title: 'OAuth 2.0',
    hasAuthorization: true,
    fields: [
      {
        name: 'custom_properties.unitid',
        label: 'Business Unit ID',
      },
    ],
  },
  API_URL_AND_KEY: {
    title: 'API Key',
    fields: [
      {
        name: 'custom_properties.url',
        label: 'API URL',
      },
      {
        name: 'api_key',
        label: 'API Key',
      },
    ],
  },
  API_CODE_AND_CREDS: {
    title: 'Account Code',
    fields: [
      {
        name: 'custom_properties.accountCode',
        label: 'Account Code',
      },
      {
        name: 'custom_properties.username',
        label: 'Username',
      },
      {
        name: 'custom_properties.password',
        label: 'Password',
      },
    ],
  },
  USERNAME_AND_PWD: {
    title: 'Account Credentials',
    fields: [
      {
        name: 'custom_properties.username',
        label: 'Username',
      },
      {
        name: 'custom_properties.password',
        label: 'Password',
      },
    ],
  },
  ACCT_ID_AND_KEY: {
    title: 'API Key',
    fields: [
      {
        name: 'custom_properties.accountId',
        label: 'Account ID',
      },
      {
        name: 'api_key',
        label: 'API Key',
      },
    ],
  },
  APP_ID_AND_KEY: {
    title: 'API Key',
    fields: [
      {
        name: 'custom_properties.appId',
        label: 'App ID',
      },
      {
        name: 'api_key',
        label: 'API Key',
      },
    ],
  },
  CUSTOMERIO_CUSTOM: {
    title: 'Customer.io API',
    fields: [
      {
        name: 'custom_properties.apiSiteId',
        label: 'Site ID',
      },
      {
        name: 'api_key',
        label: 'Tracking API Key',
      },
      {
        name: 'custom_properties.appApiKey',
        label: 'App API Key',
      },
    ],
  },
  USERNAME_AND_API_SECRET: {
    title: 'User Name and API Secret',
    fields: [
      {
        name: 'custom_properties.username',
        label: 'User Name',
      },
      {
        name: 'custom_properties.secret',
        label: 'API Secret',
      },
    ],
  },
};

export const integrationProviders = [
  {
    type: 'mailchimp',
    title: 'Mailchimp',
    icon: MailChimpIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'hubspot',
    title: 'HubSpot',
    icon: HubSpotIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'constant_contact',
    title: 'Constant Contact',
    icon: CtCtIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'keap',
    title: 'Keap',
    icon: KeapIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'active_campaign',
    title: 'ActiveCampaign',
    icon: ActiveCampaignIcon,
    availableMethods: ['API_URL_AND_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'salesforce',
    title: 'Salesforce',
    icon: SalesforceIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'salesforce_marketing',
    title: 'Salesforce Marketing Cloud',
    icon: SalesforceMarketingIcon,
    availableMethods: ['SALESFORCE_MARKETING'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'pardot',
    title: 'Salesforce Pardot',
    icon: PardotIcon,
    availableMethods: ['PARDOT'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'get_response',
    title: 'GetResponse',
    icon: GetResponseIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'sendgrid',
    title: 'Sendgrid',
    icon: SendgridIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'campaign_monitor',
    title: 'Campaign Monitor',
    icon: CampaignMonitorIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'campaign_monitor_commerce',
    title: 'Campaign Monitor Commerce',
    icon: CMCommerceIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'campaigner',
    title: 'Campaigner',
    icon: CampaignerIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'zoho',
    title: 'Zoho Campaigns',
    icon: ZohoIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'icontact',
    title: 'iContact',
    icon: IContactIcon,
    availableMethods: ['ICONTACT_CUSTOM'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'intercom',
    title: 'Intercom',
    icon: IntercomIcon,
    availableMethods: ['ACCESS_TOKEN'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'freshworks_crm',
    title: 'Freshworks CRM',
    icon: FwcrmIcon,
    availableMethods: ['API_URL_AND_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'hunter',
    title: 'Hunter',
    icon: HunterIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'mailerlite',
    title: 'MailerLite',
    icon: MailerLiteIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'klaviyo',
    title: 'Klaviyo',
    icon: KlaviyoIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Update Custom Field',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContactCustomField',
        isTextFieldRequired: true,
        textFieldLabel: 'Custom Field Name',
      },
    ],
  },
  {
    type: 'sendinblue',
    title: 'Sendinblue',
    icon: SendinblueIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'gohighlevel',
    title: 'GoHighLevel',
    icon: GoHighLevelIcon,
    availableMethods: ['ACCESS_TOKEN'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'ongage',
    title: 'Ongage',
    icon: OngageIcon,
    availableMethods: ['API_CODE_AND_CREDS'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Send Transactional Email',
        fieldLabel: 'Available Messages',
        addValueFunction: 'sendTransEmail',
        isTextFieldRequired: true,
        textFieldLabel:
          'Message ID and List ID (separated by dash like the following: 12345678-123456)',
      },
    ],
  },
  {
    type: 'ontraport',
    title: 'Ontraport',
    icon: OntraportIcon,
    availableMethods: ['APP_ID_AND_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'maropost',
    title: 'Maropost',
    icon: MaropostIcon,
    availableMethods: ['ACCT_ID_AND_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'sendlane',
    title: 'Sendlane',
    icon: SendlaneIcon,
    availableMethods: ['ACCESS_TOKEN'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'outreach',
    title: 'Outreach',
    icon: OutreachIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
        isTextFieldRequired: true,
        textFieldLabel: 'Tag Name',
      },
    ],
  },
  {
    type: 'omnisend',
    title: 'Omnisend',
    icon: OmnisendIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
        isTextFieldRequired: true,
        textFieldLabel: 'Tag Name',
      },
    ],
  },
  {
    type: 'apolloio',
    title: 'Apollo.Io',
    icon: ApolloIoIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Update Custom Field',
        fieldLabel: 'Available Custom Fields',
        fieldValueFunction: 'getCustomFields',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'birdsend',
    title: 'BirdSend',
    icon: BirdSendIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'aweber',
    title: 'AWeber',
    icon: AWeberIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'convertkit',
    title: 'ConvertKit',
    icon: ConvertKitIcon,
    availableMethods: ['API_SECRET'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'postup',
    title: 'Postup By Upland',
    icon: PostupIcon,
    availableMethods: ['USERNAME_AND_PWD'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'drip',
    title: 'Drip',
    icon: DripIcon,
    availableMethods: ['OAUTH2'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
      },
    ],
  },
  {
    type: 'mailwizz',
    title: 'MailWizz',
    icon: MailWizzIcon,
    availableMethods: ['API_URL_AND_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'customer_io',
    title: 'Customer.io',
    icon: CustomerIoIcon,
    availableMethods: ['CUSTOMERIO_CUSTOM'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Contact to Segment',
        fieldLabel: 'Available Segments',
        fieldValueFunction: 'getSegments',
        addValueFunction: 'addContactSegment',
      },
    ],
  },
  {
    type: 'emarsys',
    title: 'Emarsys',
    icon: EmarsysIcon,
    availableMethods: ['USERNAME_AND_API_SECRET'],
    availableEndpoints: [
      {
        methodFieldValue: 'Trigger External Event',
        fieldLabel: 'Available Events',
        fieldValueFunction: 'getEvents',
        addValueFunction: 'addContactEvent',
      },
    ],
  },
  {
    type: 'blastable',
    title: 'Blastable',
    icon: BlastableIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add to Contact List',
        fieldLabel: 'Available Contact Lists',
        fieldValueFunction: 'getContactLists',
        addValueFunction: 'addContact',
      },
    ],
  },
  {
    type: 'beehiiv',
    title: 'Beehiiv',
    icon: BeehiivIcon,
    availableMethods: ['API_KEY'],
    availableEndpoints: [
      {
        methodFieldValue: 'Add Contact to Publication',
        fieldLabel: 'Available Publications',
        fieldValueFunction: 'getPublications',
        addValueFunction: 'addContact',
      },
      {
        methodFieldValue: 'Add Tag to Contact',
        fieldLabel: 'Available Tags',
        fieldValueFunction: 'getTags',
        addValueFunction: 'addContactTag',
        isTextFieldRequired: true,
        textFieldLabel:
          'Tag Name and Publication ID (separated by [+] like the following: SampleTagName+pub_a11111aa-1234-1234-1a2b-1234abcd)',
      },
    ],
  },
  {
    type: 'webhook',
    title: 'Custom Webhook',
    icon: WebhookIcon,
    isHashAvailable: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_emailoctopus',
    title: 'EmailOctopus',
    icon: EmailOctopusIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_benchmark',
    title: 'Benchmark',
    icon: BenchmarkIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_autopilot',
    title: 'Autopilot',
    icon: AutopilotIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_bluetick',
    title: 'Bluetick.io',
    icon: BluetickIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_smartemailing',
    title: 'SmartEmailing',
    icon: SmartEmailingIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_emma',
    title: 'Emma',
    icon: EmmaIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_smartreach',
    title: 'SmartReach',
    icon: SmartReachIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_elastic_email',
    title: 'Elastic Email',
    icon: ElasticEmailIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_ohwo',
    title: 'OHWO',
    icon: OHWOIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_connect_365',
    title: '365 Connect',
    icon: Connect365Icon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_neverbounce',
    title: 'NeverBounce',
    icon: NeverBounceIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_autoklose',
    title: 'Autoklose',
    icon: AutokloseIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_klenty',
    title: 'Klenty',
    icon: KlentyIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_pepipost',
    title: 'Pepipost',
    icon: PepipostIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_mad_mimi',
    title: 'Mad Mimi',
    icon: MadMimiIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_mailjet',
    title: 'Mailjet',
    icon: MailjetIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_sendy',
    title: 'Sendy',
    icon: SendyIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_sendpulse',
    title: 'SendPulse',
    icon: SendPulseIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_sendfox',
    title: 'SendFox',
    icon: SendFoxIcon,
    isZapierWebhook: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: 'zapier_webhook',
    title: 'Zapier Webhook',
    icon: ZapierWebhookIcon,
    isZapierWebhook: true,
    isHashAvailable: true,
    availableMethods: ['GET', 'POST'],
  },
  {
    type: inboxMailersCampaign.type,
    title: inboxMailersCampaign.title,
    icon: CampaignIntegrationIcon,
  },
];
