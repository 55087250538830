/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
// Datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'; // DataTables Buttons Bootstrap 4 CSS
import 'datatables.net-bs4';
import CallBack from './callback';
import { fetchIntegrations } from '../../utils/apis/campaigns';
import { useAccess, appPermissions } from '../../hooks/useAccess';
import IntegrationApiKey from './IntegrationApiKey';
import {
  integrationProviders,
  integrationMethods,
  inboxMailersCampaign,
} from '../../config/datatablesIntegrations';
// MUI
import Box from '@material-ui/core/Box';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// Components
import Spinner from '../../components/Spinner';
import DeleteIcon from './delete.svg';
import EditIcon from './edit.svg';
import '../../dtButtons.css';
import Ontra from './ontraport.svg';

const IntegrationsList = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const tableRef = useRef(null);
  const [integrationDel, setIntegrationDel] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [delPass, setDelPass] = useState(false);
  const [delFail, setDelFail] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const { hasPermission } = useAccess();

  const renderIcon = integrationType => {
    const integrationConfig = integrationProviders.find(
      provider => provider.type === integrationType,
    );

    if (!integrationConfig) return '';

    const height =
      integrationConfig.type === inboxMailersCampaign.type ? 60 : 32;

    // Define specific render cases
    const renderCases = {
      ontraport: `
        <img
          width="${100}"
          height="${height}"
          src="${Ontra}"
          alt="Ontraport Icon"
          style="display: block; margin: auto;"
        />
      `,
      inbox_mailers_campaign: `
        <img
          width="${100}"
          height="${height}"
          src="${integrationConfig.icon}"
          alt="Inbox Mailers Campaign Icon"
          style="display: block; margin: auto;"
        />
      `,
    };

    // Return specific case or default case
    return (
      renderCases[integrationConfig.type] ||
      `
      <img
        width="${100}"
        height="${height}"
        src="${integrationConfig.icon}"
        alt="${integrationConfig.title || 'Integration'} Icon"
        style="display: block; margin: auto;"
      />
    `
    );
  };

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        data: 'name',
        width: '290px',
        render: function (data) {
          return data ? data : '-';
        },
      },
      {
        title: 'Type',
        data: 'type',
        render: function (data) {
          return renderIcon(data);
        },
      },
      {
        title: 'Method',
        data: 'method',
        width: '200px',
        render: function (data) {
          let methodRender = integrationMethods[data]?.title || '';
          return data ? methodRender : '-';
        },
      },
      {
        title: 'Status',
        data: 'status',
        width: '100px',
        render: function (data) {
          return data === 1
            ? `<span style='border-radius: 5px; padding: 5px; background-color: rgb(196, 248, 226); color: rgb(6, 165, 97); border: none;'>Connected</span>`
            : `<span style='border-radius: 5px; padding: 5px; background-color: rgb(255, 244, 201); color: rgb(249, 150, 0); border: none;'>Unknown</span>`;
        },
      },
      {
        title: 'Actions',
        data: null,
        width: '92px',
        orderable: false,
        render: function (data) {
          let edit = `<a href="/integrations/${data.id}/edit" class="edit-button" style="cursor: pointer; padding: 2px">
                       <img src="${EditIcon}" alt="Edit" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                     </a>`;
          let del = `<a class="delete-button" style="cursor: pointer; padding: 2px">
                       <img src="${DeleteIcon}" alt="Delete" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                     </a>`;

          return `<div style="display: inline-block;">${edit}</div><div style="display: inline-block;">${del}</div>`;
        },
      },
    ],
    [],
  );

  const getParamsFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    params.draw = parseInt(urlParams.get('draw')) || 1;
    params.start = parseInt(urlParams.get('start')) || 0;
    params.length = parseInt(urlParams.get('length')) || 500; // Set default length to 25

    const order = urlParams.get('order');
    if (order) {
      params.order = JSON.parse(order);
    }

    const search = urlParams.get('search');
    if (search) {
      params.search = JSON.parse(search);
    }

    return params;
  };

  const fetchData = async params => {
    // Get existing params from the URL
    const urlParams = getParamsFromURL();

    // Merge existing URL params with the new params
    const mergedParams = { ...urlParams, ...params };

    // Serialize the merged params, excluding the 'columns' property
    const { columns, ...paramsWithoutColumns } = mergedParams;
    const serializedParams = {};

    for (let key in paramsWithoutColumns) {
      if (paramsWithoutColumns.hasOwnProperty(key)) {
        if (typeof paramsWithoutColumns[key] === 'object') {
          serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
        } else {
          serializedParams[key] = paramsWithoutColumns[key];
        }
      }
    }

    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);

    // Make the POST request with the merged params
    const response = await fetchIntegrations(mergedParams);
    return response;
  };

  const handleDelete = async id => {
    // axios.put(`/integrations/${row.id}/archive`)
    axios
      .put(`/integrations/${id}/archive`)
      .then(pass => {
        setDelPass(true);
        setIntegrationDel(false);
        setDeleteId(0);
      })
      .catch(err => {
        console.error(err);
        setDelFail(true);
        setIntegrationDel(false);
        setFailMessage(err?.message || 'Something went wrong');
        setDeleteId(0);
      })
      .finally(() => {
        setTimeout(() => {
          setDelPass(false);
        }, 4000);
      });
  };

  const handleClose = () => {
    setDelPass(false);
    setDelFail(false);
  };

  const debouncedSearch = value => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout
    const timeout = setTimeout(() => {
      const api = $(tableRef.current).DataTable();
      api.search(value).draw();
    }, 750);

    setSearchTimeout(timeout);
  };

  useEffect(() => {
    const initialParams = getParamsFromURL();

    // Check if the 'draw' parameter exists in the URL
    if (!initialParams.draw) {
      // Set the URL to the first draw info if 'draw' is missing
      const initialDrawParams = {
        draw: 1,
        start: 0,
        length: 25, // Set default length to 25
        order: JSON.stringify([[0, 'asc']]),
        search: JSON.stringify({ value: '', regex: false }),
      };
      const queryString = new URLSearchParams(initialDrawParams).toString();
      const newUrl = `${window.location.pathname}?${queryString}`;
      window.history.replaceState(null, '', newUrl);
    }

    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        // dom: '<"d-flex justify-content-between"<"date-range-inputs">fBl>rtip',
        dom: '<"d-flex align-items-center dt-controls"lf>rtip',
        responsive: true,
        scrollX: true,
        lengthMenu: [
          [10, 25, 50, 100, 500],
          [10, 25, 50, 100, 500],
        ],
        displayStart: initialParams.start || 0, // Set initial start value from URL
        pageLength: initialParams.length || 500, // Set initial length value from URL
        order: initialParams.order || [], // Set initial order from URL
        search: initialParams.search || { value: '', regex: false }, // Set initial search from URL
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              callback({
                draw: data.draw,
                recordsTotal: result.recordsTotal || 0,
                recordsFiltered: result.recordsFiltered || 0,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          applyCustomStyles(api);
          attachEventListeners(api);

          // Bind the search input to the DataTables search functionality
          const searchBox = $('input[type="search"]');
          searchBox.on('keyup', function () {
            debouncedSearch(this.value); // Call debounced search
          });

          // Ensure search param is correctly set on initialization
          if (initialParams.search && initialParams.search.value) {
            api.search(initialParams.search.value).draw();
          }
        },
        drawCallback: function (settings) {
          const api = this.api();
          const draw = settings.iDraw || initialParams.draw; // Preserve the current draw number
          const start = settings._iDisplayStart; // Get the current start value
          const length = settings._iDisplayLength; // Get the current length value
          const order = api.order(); // Get the current order
          const search = api.search(); // Get the current search value
          attachEventListeners(api);

          // Update the URL with the current state of DataTable
          const queryString = new URLSearchParams({
            draw: draw,
            start: start,
            length: length,
            order: JSON.stringify(order),
            search: JSON.stringify({ value: search, regex: false }), // Sync search term to URL
          }).toString();

          const newUrl = `${window.location.pathname}?${queryString}`;
          window.history.replaceState(null, '', newUrl);
          applyCustomStyles(api);
        },
      });
    }

    function applyCustomStyles(api) {
      // Continue applying other custom styles
      $(api.table().container())
        .find('label, input, select, th, td, .dt-info')
        .css({
          'font-size': '14px',
          'font-weight': 'bold',
        });
      $(api.table().container()).find('label').css({
        padding: '5px',
      });
      $(api.table().container()).find('input, select').css({
        margin: '10px',
      });
      $(api.table().container()).find('thead tr th').css({
        'padding-left': '10px',
        'text-align': 'left',
        'border-bottom': '2px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
      });
      $(api.table().container()).find('tfoot tr th').css({
        'border-bottom': '2px solid #dee2e6',
      });
      $(api.table().container()).find('.dt-info').css({
        'padding-left': '10px',
      });
      $(api.table().container()).find('tbody tr td').css({
        'padding-left': '10px',
        'border-bottom': '1px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
        'white-space': 'normal', // Change to normal to wrap text
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      });
      $(api.table().container())
        .find('tbody tr td:last-child, thead tr th:last-child')
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
        )
        .css({
          'border-right': '1px solid #dee2e6',
          'background-color': 'inherit',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
        )
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
        .css({
          'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
        });

      $(api.table().container()).find('ul.pagination').css({
        display: 'flex', // Use flexbox to align items horizontally
        justifyContent: 'center', // Center align pagination controls
        padding: '0', // Remove default padding
        marginTop: '10px', // Add some spacing above the pagination controls
        listStyle: 'none', // Remove default list styling
      });

      $(api.table().container()).find('ul.pagination li').css({
        display: 'inline',
        margin: '0 2px', // Add spacing between pagination items
      });

      $(api.table().container()).find('ul.pagination li a').css({
        display: 'inline-block', // Ensure links are inline
        padding: '5px 10px', // Add padding for clickable area
        border: '1px solid #ddd', // Border for pagination items
        borderRadius: '5px', // Rounded corners for pagination items
        textDecoration: 'none', // Remove underline from links
        color: '#333', // Text color
      });

      $(api.table().container()).find('ul.pagination li.active a').css({
        backgroundColor: '#E00001', // Highlight background color for the active page
        color: 'white', // Text color for active page
      });

      $(api.table().container()).find('ul.pagination li a:hover').css({
        backgroundColor: '#f1f1f1', // Background color on hover
        color: '#333', // Text color on hover
      });

      // Apply Flexbox styling to the parent container of "entries per page" and "Search"
      $(api.table().container()).find('.dt-controls').css({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%', // Ensure it fills the entire width of the container
      });

      // Increase size for all inputs with ids matching dt-search-*
      $(api.table().container()).find('input[id^="dt-search-"]').css({
        height: '2.5rem',
        width: '15rem',
      });

      // Increase size for all selects with ids matching dt-length-*
      $(api.table().container()).find('select[id^="dt-length-"]').css({
        height: '2.5rem',
        width: '3rem',
      });

      // Apply flexbox to div.dt-length
      $(api.table().container()).find('div.dt-length').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('div.dt-search').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('.buttons-html5, .buttons-print').css({
        'background-color': '#E00001',
        'border-width': '2px',
        'border-radius': '10px',
        'border-color': '#E00001',
        color: 'white',
        padding: '10px',
        cursor: 'pointer', // Add this line to change the cursor on hover
      });
    }

    function attachEventListeners(api) {
      $('.delete-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setIntegrationDel(true);
          setDeleteId(rowData.id);
        });

      // $('.contacts-button')
      //   .off('click')
      //   .on('click', function () {
      //     const row = $(this).closest('tr');
      //     const rowData = api.row(row).data();

      //     setContactModal(true);
      //     setContactID(rowData.id);
      //   });
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns]);

  return (
    <>
      {hasPermission(appPermissions.apiAccess) && <IntegrationApiKey />}
      <Snackbar
        open={delPass}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#4caf50',
          }}
          message={<Typography>Successfully Deleted Integration</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Snackbar
        open={delFail}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#E00001',
          }}
          message={<Typography>{failMessage}</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      {integrationDel ? (
        <CallBack id={deleteId} cbNo={setIntegrationDel} cbYes={handleDelete} />
      ) : null}
      <Box>
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
      {isLoading && (
        <Box display="flex" paddingTop={2} justifyContent="center">
          <Spinner />
        </Box>
      )}
    </>
  );
};

export default IntegrationsList;
