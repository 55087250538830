/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
// Datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4';
// MUI
import Box from '@material-ui/core/Box';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// Components
import Spinner from '../../components/Spinner';
import '../../dtButtons.css';

const formatDate = date => {
  return date.toISOString().split('T')[0];
};

const calculateMaxStartDate = () => {
  const today = new Date();
  const maxStartDate = new Date(today.setDate(today.getDate() - 60));
  return formatDate(maxStartDate);
};

const TriggerList = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const tableRef = useRef(null);
  const [exportPass, setExportPass] = useState(false);
  const [exportFail, setExportFail] = useState(false);
  const today = new Date();

  // Get initial dates from URL or set them to today
  const getInitialDate = (paramName, defaultDate) => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateFromUrl = urlParams.get(paramName);
    return dateFromUrl || formatDate(defaultDate);
  };

  const [startDate, setStartDate] = useState(
    getInitialDate('dateStart', today),
  );
  const [endDate, setEndDate] = useState(getInitialDate('dateEnd', today));
  const maxStartDate = calculateMaxStartDate(); // Calculate the max start date

  const handleClose = () => {
    setExportPass(false);
    setExportFail(false);
  };

  const columns = useMemo(
    () => [
      { title: 'ID', data: 'id' },
      { title: 'Trigger Name', data: 'trigger_name' },
      { title: 'Partner ID', data: 'partner_id' },
      { title: 'Partner Name', data: 'partner_name' },
      { title: 'Daily Trigger Limit', data: 'daily_trigger_limit' },
      { title: 'Date', data: 'date' },
      { title: 'Success Count', data: 'success_count' },
      { title: 'Fail Count', data: 'fail_count' },
      { title: 'Total Count', data: 'total_count' },
      { title: 'Overage', data: 'overage' },
      { title: 'List Size', data: 'list_size' },
      // { title: 'Success Code Value', data: 'success_code_value' },
      { title: 'Pause Code Value', data: 'pause_code_value' },
      // {
      //   title: 'Success Code Counts',
      //   data: 'status_code_counts',
      //   render: function (data) {
      //     return JSON.stringify(data);
      //   },
      // },
      {
        title: 'Pause Code Counts',
        data: 'pause_code_value',
        render: function (data) {
          return JSON.stringify(data);
        },
      },
    ],
    [],
  );

  const getParamsFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    params.draw = parseInt(urlParams.get('draw')) || 1;
    params.start = parseInt(urlParams.get('start')) || 0;
    params.length = parseInt(urlParams.get('length')) || 25; // Default to 25

    const order = urlParams.get('order');
    if (order) {
      try {
        params.order = JSON.parse(order);
      } catch (e) {
        console.error("Error parsing 'order' parameter:", e);
        params.order = [[0, 'asc']]; // Fallback to default order
      }
    }

    const search = urlParams.get('search');
    if (search) {
      try {
        params.search = JSON.parse(search);
      } catch (e) {
        console.error("Error parsing 'search' parameter:", e);
        params.search = { value: '', regex: false }; // Fallback to default search
      }
    }

    return params;
  };

  const updateURLParams = params => {
    const serializedParams = {};
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        if (typeof params[key] === 'object') {
          serializedParams[key] = JSON.stringify(params[key]);
        } else {
          serializedParams[key] = params[key];
        }
      }
    }
    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);
  };

  const fetchData = async params => {
    // Get existing params from the URL
    const urlParams = getParamsFromURL();

    // Ensure the search term from DataTable is included in the params
    params['search'] = params.search ||
      urlParams.search || { value: '', regex: false };
    params['dateStart'] = startDate;
    params['dateEnd'] = endDate;

    // Merge existing URL params with the new params
    const mergedParams = { ...urlParams, ...params };

    // Update the URL
    updateURLParams(mergedParams);

    // Make the POST request with the merged params
    const response = await axios.post(
      `/triggers/datatables?datatables=stats`,
      mergedParams,
    );
    return response;
  };

  const fetchAllDataAndExport = async () => {
    const table = $(tableRef.current).DataTable();
    const params = table.ajax.params();

    try {
      const response = await axios.post(
        '/triggers/datatables?datatables=stats&export=stats',
        params,
      );
      if (response.data && response.data.length > 0) {
        const csvData = convertToCSV(response.data);
        downloadCSV(csvData, 'export.csv');
        setExportPass(true);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
      setExportFail(true);
    }
    setTimeout(() => {
      setExportFail(false);
    }, 4000);
  };

  const convertToCSV = data => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const val = row[header];
        return typeof val === 'string' ? `"${val.replace(/"/g, '""')}"` : val;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    const initialParams = getParamsFromURL();

    // Check if the 'draw' parameter exists in the URL
    if (!initialParams.draw) {
      // Set the URL to the first draw info if 'draw' is missing
      const initialDrawParams = {
        draw: 1,
        start: 0,
        length: 25, // Default to 25 entries per page
        order: JSON.stringify([[0, 'asc']]),
        search: JSON.stringify({ value: '', regex: false }),
        dateStart: startDate,
        dateEnd: endDate,
      };
      updateURLParams(initialDrawParams);
    }

    const initializeDataTable = () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        dom: '<"d-flex align-items-center dt-controls"lfB>rtip',
        responsive: true,
        scrollX: true,
        displayStart: initialParams.start || 0,
        pageLength: initialParams.length || 25, // Set default to 25 entries per page
        order: initialParams.order || [[0, 'asc']],
        search: initialParams.search || { value: '', regex: false },
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              callback({
                draw: data.draw,
                recordsTotal: result.recordsTotal || 0,
                recordsFiltered: result.recordsFiltered || 0,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        buttons: [
          {
            extend: 'csv',
            text: 'Export Max Rows',
            titleAttr:
              'Max Export 25,000 rows. For more rows, please contact support',
            action: function (e, dt, node, config) {
              fetchAllDataAndExport();
            },
          },
        ],
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          // Apply initial search value if present
          if (initialParams.search && initialParams.search.value) {
            api.search(initialParams.search.value).draw();
          }
          applyCustomStyles(api);
        },
        drawCallback: function (settings) {
          const api = this.api();
          const draw = settings.iDraw || initialParams.draw;
          const start = settings._iDisplayStart;
          const length = settings._iDisplayLength;
          const order = api.order();
          const search = api.search();

          const queryString = new URLSearchParams({
            draw: draw,
            start: start,
            length: length,
            order: JSON.stringify(order),
            search: JSON.stringify({ value: search, regex: false }),
            dateStart: startDate,
            dateEnd: endDate,
          }).toString();

          const newUrl = `${window.location.pathname}?${queryString}`;
          window.history.replaceState(null, '', newUrl);
          applyCustomStyles(api);
        },
      });
    };

    initializeDataTable();

    function applyCustomStyles(api) {
      $(api.table().container()).find('.makeStyles-paper-35').css({
        'max-width': '1200px',
      });
      $(api.table().container())
        .find('label, input, select, th, td, .dt-info')
        .css({
          'font-size': '14px',
          'font-weight': 'bold',
        });
      $(api.table().container()).find('label').css({
        padding: '5px',
      });
      $(api.table().container()).find('input, select').css({
        margin: '10px',
      });
      $(api.table().container()).find('.date-rage-input').css({
        padding: '8px',
      });
      $(api.table().container()).find('thead tr th').css({
        'padding-left': '10px',
        'text-align': 'left',
        'border-bottom': '2px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
      });
      $(api.table().container()).find('tfoot tr th').css({
        'border-bottom': '2px solid #dee2e6',
      });
      $(api.table().container()).find('.dt-info').css({
        'padding-left': '10px',
      });
      $(api.table().container()).find('tbody tr td').css({
        'padding-left': '5px',
        // 'border-bottom': '1px solid #dee2e6',
        // 'border-right': '1px solid #dee2e6',
        // 'white-space': 'normal',
        // overflow: 'hidden',
        // 'text-overflow': 'ellipsis',
      });
      $(api.table().container())
        .find('tbody tr td:last-child, thead tr th:last-child')
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
        )
        .css({
          'border-right': '1px solid #dee2e6',
          'background-color': 'inherit',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
        )
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
        .css({
          'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
        });

      $(api.table().container()).find('ul.pagination').css({
        display: 'flex',
        justifyContent: 'center',
        padding: '0',
        marginTop: '10px',
        listStyle: 'none',
      });

      $(api.table().container()).find('.date-range-inputs').css({
        'font-size': '14px',
        'font-weight': 'bold',
      });

      $(api.table().container()).find('ul.pagination li').css({
        display: 'inline',
        margin: '0 2px',
      });

      $(api.table().container()).find('ul.pagination li a').css({
        display: 'inline-block',
        padding: '5px 10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        textDecoration: 'none',
        color: '#333',
      });

      $(api.table().container()).find('ul.pagination li.active a').css({
        backgroundColor: '#E00001',
        color: 'white',
      });

      $(api.table().container()).find('ul.pagination li a:hover').css({
        backgroundColor: '#f1f1f1',
        color: '#333',
      });

      $(api.table().container()).find('.dt-controls').css({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      });

      // Increase size for all inputs with ids matching dt-search-*
      $(api.table().container()).find('input[id^="dt-search-"]').css({
        height: '2.5rem',
        width: '15rem',
      });

      // Increase size for all selects with ids matching dt-length-*
      $(api.table().container()).find('select[id^="dt-length-"]').css({
        height: '2.5rem',
        width: '3rem',
      });

      // Apply flexbox to div.dt-length
      $(api.table().container()).find('div.dt-length').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('div.dt-search').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('.buttons-html5, .buttons-print').css({
        'background-color': '#4CAF50',
        'border-width': '0',
        'border-radius': '5px',
        color: 'white',
      });
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns, startDate, endDate]);

  const handleDateChange = (setter, value, paramName) => {
    const maxStartDate = calculateMaxStartDate();
    if (paramName === 'dateStart' && value < maxStartDate) {
      value = maxStartDate; // Restrict startDate to be within 60 days
    }
    setter(value);
    updateURLParams({
      [paramName]: value,
      dateStart: startDate,
      dateEnd: endDate,
    });
  };

  return (
    <>
      <Snackbar
        open={exportPass}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#4caf50',
          }}
          message={
            <Typography>
              Max Export 25,000 rows. For more rows, please contact support
            </Typography>
          }
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Snackbar
        open={exportFail}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#E00001',
          }}
          message={<Typography>Unable to Export</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Box>
        <div className="date-picker-container">
          <label
            htmlFor="start-date"
            style={{ fontWeight: 'bold', paddingRight: '5px' }}
          >
            Start Date:
          </label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            min={maxStartDate} // Restrict selection to no earlier than 60 days ago
            onChange={e =>
              handleDateChange(setStartDate, e.target.value, 'dateStart')
            }
            style={{ fontWeight: 'bold' }}
          />
          &nbsp;
          <label
            htmlFor="end-date"
            style={{ fontWeight: 'bold', padding: '5px' }}
          >
            End Date:
          </label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={e =>
              handleDateChange(setEndDate, e.target.value, 'dateEnd')
            }
            style={{ fontWeight: 'bold' }}
          />
        </div>

        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
      {isLoading && (
        <Box display="flex" paddingTop={2} justifyContent="center">
          <Spinner />
        </Box>
      )}
    </>
  );
};

export default TriggerList;
