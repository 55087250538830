import axios from 'axios';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useCallback, useMemo, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useStyles } from './styles';
import IntegrationBuilder from '../../pages/IntegrationBuilder';
import RefreshButton from '../RefreshButton';

function IntegrationDropdown({ field, form }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(
    form?.initialValues?.integration_id > 0,
  );

  const {
    data: integrationList = [],
    isLoading: isIntegrationListLoading,
    isFetching: isIntegrationListFetching,
    refetch: refetchIntegrationList,
  } = useQuery('integrations', () => axios.get('/integrations?status=1'), {
    staleTime: 0,
    select: data =>
      data.map(integration => ({
        value: integration.id,
        label: integration.name,
        type: integration.type,
      })),
  });

  const isLoading = isIntegrationListLoading || isIntegrationListFetching;

  const emptyItem = useMemo(() => {
    if (isLoading) {
      return <MenuItem value="">Loading...</MenuItem>;
    }

    if (!field.value) {
      return <MenuItem value="">- No Integration -</MenuItem>;
    }

    return null;
  }, [field.value, isLoading]);
  const handleChange = useCallback(
    e => {
      setDisplay(false);
      if (e.target.value === 'NEW') {
        setOpen(true);
        return;
      }

      field.onChange(e);
    },
    [field],
  );
  return (
    <>
      {open && (
        <IntegrationBuilder
          mode="dialog"
          onClose={() => setOpen(false)}
          onSubmit={({ id }) => form.setFieldValue(field.name, id)}
        />
      )}
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '-20px' }}
      >
        <p style={{ color: '#5A607F', fontSize: '14px' }}>Integration</p>
        {display ? (
          <Tooltip title="Edit Integration" arrow>
            <LinkIcon
              style={{ marginLeft: '8px', cursor: 'pointer' }}
              onClick={() =>
                (window.location.href = `/integrations/${form?.values?.integration_id}/edit`)
              }
            />
          </Tooltip>
        ) : null}
      </div>
      <TextField
        {...field}
        select
        // label="Integration"
        value={isLoading ? '' : field.value}
        onChange={handleChange}
        SelectProps={{
          displayEmpty: true,
          disabled: isLoading,
          className: field.value ? classes.clearableSelect : '',
        }}
        InputProps={{
          endAdornment: field.value ? (
            <>
              <InputAdornment
                position="end"
                className={classes.clearSelectIcon}
                onClick={() => form.setFieldValue(field.name, '')}
              >
                <ClearIcon fontSize="small" color="action" />
              </InputAdornment>
            </>
          ) : null,
        }}
        helperText={form.touched[field.name] && form.errors[field.name]}
        error={form.touched[field.name] && Boolean(form.errors[field.name])}
      >
        <MenuItem value="NEW">
          <Typography
            color="primary"
            variant="subtitle2"
            className={classes.newOption}
          >
            <AddIcon
              fontSize="small"
              sx={{ marginRight: 1, marginLeft: -0.5 }}
            />
            Create A New Integration
          </Typography>
          {integrationList.length > 0 && (
            <RefreshButton
              onClick={refetchIntegrationList}
              isLoading={isLoading}
            />
          )}
        </MenuItem>

        {emptyItem}
        {integrationList.map((item, key) => (
          <MenuItem key={key} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

IntegrationDropdown.propTypes = {
  meta: PropTypes.object,
  form: PropTypes.object,
};

export default IntegrationDropdown;
