import axios from 'axios';

const API_BASE_URL =
  process.env.REACT_APP_BASE_WYSIWYG_API_URL ||
  'https://dev-wysiwyg-im.smartbrandscompany.com/wysiwyg/api/v1';

export const createCampaign = async (partnerId, data) => {
  const apiUrl = `${API_BASE_URL}/campaigns/${partnerId}/create`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const createCampaignAsIntegration = async (partnerId, data) => {
  const apiUrl = `${API_BASE_URL}/campaigns/${partnerId}/integration/create`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const updateCampaignStatus = async (campaignId, data) => {
  const apiUrl = `${API_BASE_URL}/campaigns/${campaignId}/status`;

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};
export const updateCampaign = async data => {
  const apiUrl = `${API_BASE_URL}/campaigns/${data.id}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getCampaigns = async (
  partnerId,
  page = 1,
  size = 10,
  status = 1,
) => {
  const apiUrl = `${API_BASE_URL}/partners/${partnerId}/campaigns?page=${page}&size=${size}&status=${status}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getCampaign = async (campaignId, partnerId) => {
  const apiUrl = `${API_BASE_URL}/campaigns/${campaignId}/${partnerId}/get`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getCampaignByIntegrationId = async integrationId => {
  const apiUrl = `${API_BASE_URL}/campaigns/${integrationId}/integration`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const deleteCampaign = async campaignId => {
  const apiUrl = `${API_BASE_URL}/campaigns/${campaignId}/archive`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    if (response.ok) {
      return response;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const fetchIntegrations = async params => {
  // Fetch integrations
  const integrationsData = await axios.post(
    '/integrations?datatables=integrations',
    params,
  );

  if (!Array.isArray(integrationsData?.data) || !integrationsData?.data) {
    console.error('Unexpected data format from /integrations');
    return [];
  }
  let dataCopy = integrationsData?.data.slice(0);

  // Fetch campaigns for each integration and add the campaignId
  const results = await Promise.allSettled(
    dataCopy.map(async integration => {
      const { data: campaignData } = await getCampaignByIntegrationId(
        integration.id,
      );
      if (campaignData?.campaign_designs?.length > 0) return null; // Don't add integrations with campaigns that has designs
      if (!campaignData?.id) return integration; // If there's no campaignId, return original integration
      return {
        ...integration,
        campaignId: campaignData?.id,
        campaign_designs: campaignData?.campaign_designs,
      };
    }),
  );

  // Extract successful promises' values to get the final integrations list
  const finalIntegrations = results
    .map(result => (result.status === 'fulfilled' ? result.value : null))
    .filter(Boolean); // This filter will remove any null values
  integrationsData.data = finalIntegrations;
  return integrationsData;
};

export const createCampaignActions = async (partnerId, data) => {
  const apiUrl = `${API_BASE_URL}/campaigns/${partnerId}/integration/create`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};
export const updateCampaignActions = async data => {
  const apiUrl = `${API_BASE_URL}/campaigns/${data.id}/action/multiple`;

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData);
    }
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};
